export default {
    title: 'Outbound Quality',
    location: '/business/fuo/customer-order-quality',
    tabs: [
        {
            name: 'By Country',
            link: `/business/fuo/customer-order-quality`
        },
        {
            name: 'Trend',
            link: `/business/fuo/customer-order-quality/:market`
        },
        // {
        //     name: 'Top 10',
        //     link: '/customer/customer-order-quality/'
        // }
    ]
}
